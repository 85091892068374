// src/components/Contact.js
import React from 'react';

const Contact = () => {
  return (
    <div className="min-h-screen bg-gray-600 text-white p-8">
      <h2 className="text-4xl font-bold mb-8 text-center">Contact Us</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Google Maps Integration */}
        <div className="bg-gray-700 p-4 rounded-lg shadow-lg">
          <h3 className="text-2xl font-bold mb-4 text-deep-slate-blue">Our Office Location</h3>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3737.620702580643!2d85.82931037554454!3d20.480766181035083!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a1911d72c470dff%3A0x1285ee88b540c01f!2sArkihus%20designers!5e0!3m2!1sen!2sin!4v1726347350977!5m2!1sen!2sin" width="100%"
            height="300"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            title="Office Location"
          ></iframe>
        </div>

        {/* Contact Form */}
        <div className="bg-gray-700 p-4 rounded-lg shadow-lg">
          <h3 className="text-2xl font-bold mb-4 text-deep-slate-blue">Send Us a Message</h3>
          <form className="space-y-4">
            <div>
              <label className="block text-sm">Name</label>
              <input type="text" className="w-full p-2 bg-gray-800 rounded" />
            </div>
            <div>
              <label className="block text-sm">Email</label>
              <input type="email" className="w-full p-2 bg-gray-800 rounded" />
            </div>
            <div>
              <label className="block text-sm">Message</label>
              <textarea className="w-full p-2 bg-gray-800 rounded"></textarea>
            </div>
            <button type="submit" className="w-full py-2 bg-gold hover:bg-yellow-500 rounded">Submit</button>
          </form>
        </div>
      </div>

      {/* Social Handles and Contact Info */}
      <div className="mt-12 text-center">
        <h3 className="text-2xl font-bold mb-4 text-deep-slate-blue">Connect With Us</h3>
        <div className="flex justify-center gap-6 mb-8">
          <a href="mailto:info@example.com" className="text-gold hover:text-yellow-500">Email Us</a>
          <a href="https://wa.me/1234567890" className="text-gold hover:text-yellow-500" target="_blank" rel="noopener noreferrer">WhatsApp</a>
          <a href="https://facebook.com/yourpage" className="text-gold hover:text-yellow-500" target="_blank" rel="noopener noreferrer">Facebook</a>
          <a href="https://twitter.com/yourprofile" className="text-gold hover:text-yellow-500" target="_blank" rel="noopener noreferrer">Twitter</a>
          <a href="https://instagram.com/yourprofile" className="text-gold hover:text-yellow-500" target="_blank" rel="noopener noreferrer">Instagram</a>
        </div>
        <p className="text-light-slate-gray">1234 Street Name, City, Country</p>
      </div>
    </div>
  );
};

export default Contact;
