import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  const images = [
    './assets/arch1.jpeg',
    './assets/arch2.jpeg',
    './assets/arch3.jpeg',
    './assets/arch4.jpg',
    './assets/arch5.jpg'
  ];

  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 5000); // Change the slide every 5 seconds (5000ms)

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [images.length]);

  return (
    <>
      <section id="home" className="relative overflow-hidden h-screen bg-gray-900 flex items-center justify-center">
        <div className="absolute inset-0 w-full h-full bg-black">
          {/* Image Slider */}
          <img
            src={images[currentImage]}
            alt={`Building ${currentImage + 1}`}
            className="object-cover w-full h-full transition-opacity duration-1000 ease-in-out opacity-100"
          />
        </div>

        {/* Hero Content */}
        <div className="absolute z-10 w-full px-8 flex justify-between items-center top-0 py-6">
          {/* Logo on the left */}
          <div className="text-white text-2xl font-bold">
            <Link to="/">
              <img src="./assets/logo1.png" alt="Logo" className="h-20" /> {/* Adjust the logo height */}
            </Link>
          </div>

          {/* Navigation links on the right */}
          <nav className="text-white space-x-6 text-lg">
            <Link to="/portfolio" className="hover:text-yellow-500 transition">Portfolio</Link>
            <Link to="/projects" className="hover:text-yellow-500 transition">Projects</Link>
            <Link to="/services" className="hover:text-yellow-500 transition">Services</Link>
            <Link to="/contact" className="hover:text-yellow-500 transition">Contact</Link>
          </nav>
        </div>

        <div className="absolute z-10 text-center text-white">
          <h2 className="text-5xl font-bold">Building the Future</h2>
        </div>
      </section>

      {/* About Us Section */}
      <section id="about" className="py-16 bg-gray-100">
        <div className="container mx-auto px-6">
          <h3 className="text-3xl font-bold text-center text-gray-900">About Us</h3>
          <p className="text-lg text-center mt-4 text-gray-600">
            We are a leading architecture firm with a passion for creating innovative, sustainable designs.
            With over 20 years of experience, we bring visions to life and redefine urban landscapes.
          </p>
        </div>
      </section>

      {/* Services Section */}
      <section id="services" className="py-16 bg-white">
        <div className="container mx-auto px-6">
          <h3 className="text-3xl font-bold text-center text-gray-900">Our Services</h3>
          <div className="flex flex-wrap justify-center mt-8">
            <div className="w-full md:w-1/3 p-4">
              <div className="p-6 rounded-lg shadow-lg bg-gray-100">
                <h4 className="text-xl font-bold mb-4 text-gray-800">Residential Design</h4>
                <p className="text-gray-600">
                  Tailored, innovative designs for homes, ensuring a balance between comfort and aesthetics.
                </p>
              </div>
            </div>
            <div className="w-full md:w-1/3 p-4">
              <div className="p-6 rounded-lg shadow-lg bg-gray-100">
                <h4 className="text-xl font-bold mb-4 text-gray-800">Commercial Architecture</h4>
                <p className="text-gray-600">
                  Modern commercial spaces that combine functionality with aesthetic appeal.
                </p>
              </div>
            </div>
            <div className="w-full md:w-1/3 p-4">
              <div className="p-6 rounded-lg shadow-lg bg-gray-100">
                <h4 className="text-xl font-bold mb-4 text-gray-800">Sustainable Solutions</h4>
                <p className="text-gray-600">
                  Environmentally-friendly designs that help reduce carbon footprints and promote green living.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Team Section */}
      <section id="team" className="py-16 bg-gray-100">
        <div className="container mx-auto px-6">
          <h3 className="text-3xl font-bold text-center text-gray-900">Meet Our Team</h3>
          <div className="flex flex-wrap justify-center mt-8">
            <div className="w-full md:w-1/4 p-4 text-center">
              <img src="./assets/silu.jpg" alt="Team Member 1" className="rounded-full w-32 mx-auto mb-4" />
              <h4 className="text-xl font-bold text-gray-800">Ar. Suhasini Muduli</h4>
              <p className="text-gray-600">Lead Architect</p>
            </div>
            <div className="w-full md:w-1/4 p-4 text-center">
              <img src="./assets/pic.png" alt="Team Member 2" className="rounded-full w-32 mx-auto mb-4" />
              <h4 className="text-xl font-bold text-gray-800">Sachin Kumar Muduli</h4>
              <p className="text-gray-600">Software Developer</p>
            </div>
            {/* <div className="w-full md:w-1/4 p-4 text-center">
              <img src="/path/to/team-member3.jpg" alt="Team Member 3" className="rounded-full w-32 mx-auto mb-4" />
              <h4 className="text-xl font-bold text-gray-800">Michael Lee</h4>
              <p className="text-gray-600">Interior Designer</p>
            </div> */}
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section id="contact" className="py-16 bg-white">
        <div className="container mx-auto px-6">
          <h3 className="text-3xl font-bold text-center text-gray-900">Get In Touch</h3>
          <p className="text-lg text-center mt-4 text-gray-600">
            Whether you're ready to start your project or have a few questions, we'd love to hear from you.
          </p>
          <div className="flex justify-center mt-8">
            <a href="/contact" className="px-6 py-3 font-bold rounded bg-yellow-500 text-white">
              Contact Us
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
