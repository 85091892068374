// src/components/Portfolio.js
import React from 'react';
import { motion } from 'framer-motion'; // Import Framer Motion for animations

const Portfolio = () => {
  // Define animation variants for images
  const fadeIn = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.8 } },
  };

  const projects = [
    { id: 1, title: 'Modern Skyscraper', imgSrc: '/path/to/image1.jpg', description: 'A modern skyscraper in the city center.' },
    { id: 2, title: 'Sustainable Complex', imgSrc: '/path/to/image2.jpg', description: 'A sustainable residential complex with eco-friendly features.' },
    { id: 3, title: 'Innovative Office', imgSrc: '/path/to/image3.jpg', description: 'An innovative office building with cutting-edge design.' },
  ];

  return (
    <section id="portfolio" className="py-12 bg-alabaster">
      <div className="container mx-auto text-center">
        <motion.h2
          className="text-4xl font-bold text-deep-slate-blue mb-8"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          Our Portfolio
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {projects.map(project => (
            <motion.div
              key={project.id}
              className="bg-white rounded-lg shadow-lg overflow-hidden"
              initial="hidden"
              animate="visible"
              variants={fadeIn}
            >
              <img src={project.imgSrc} alt={project.title} className="w-full h-64 object-cover transition-transform transform hover:scale-105" />
              <div className="p-6">
                <h3 className="text-2xl font-bold text-deep-slate-blue mb-2">{project.title}</h3>
                <p className="text-light-slate-gray">{project.description}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
