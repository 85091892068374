// src/components/Projects.js
import React from 'react';
import { motion } from 'framer-motion'; // Add animation using Framer Motion

const Projects = () => {
  const projectList = [
    { id: 1, name: 'Project One', description: 'A modern skyscraper in the city center.' },
    { id: 2, name: 'Project Two', description: 'A sustainable residential complex.' },
    { id: 3, name: 'Project Three', description: 'An innovative office building.' },
    { id: 4, name: 'Project Four', description: 'A luxurious private villa on the coast.' },
    { id: 5, name: 'Project Five', description: 'A high-tech corporate campus.' },
    { id: 6, name: 'Project Six', description: 'A cultural arts center and museum.' },
  ];

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } }
  };

  return (
    <div className="min-h-screen bg-alabaster text-deep-slate-blue py-16 px-8">
      <motion.h2 
        className="text-5xl font-bold mb-8 text-center" 
        initial="hidden" 
        animate="visible" 
        variants={fadeInUp}
      >
        Our Projects
      </motion.h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {projectList.map(project => (
          <motion.div 
            key={project.id} 
            className="bg-white p-6 rounded-lg shadow-xl hover:shadow-2xl transition duration-300 transform hover:scale-105"
            initial="hidden"
            animate="visible"
            variants={fadeInUp}
          >
            <h3 className="text-3xl font-semibold mb-4 text-gold">{project.name}</h3>
            <p className="text-light-slate-gray mb-4">{project.description}</p>
            <a href={`/projects/${project.id}`} className="text-gold font-bold hover:text-yellow-500">
              View Details
            </a>
          </motion.div>
        ))}
      </div>
    </div>
  );
}

export default Projects;
