import React from 'react';

const Header = () => {
  return (
    <header className="bg-gray-800 text-white p-6 shadow-lg">
      <div className="container mx-auto flex justify-between items-center">
        
        {/* Logo without animation */}
        <div className="flex items-center">
          <img 
            src="./assets/logo2.png" 
            alt="Company Logo" 
            className="h-12 w-18 mr-4" 
          />
          <h1 className="text-3xl font-semibold tracking-tight hover:text-gray-300 transition-colors duration-300">
            Arkihus Designers
          </h1>
        </div>

        {/* Navigation Links with subtle underline animation */}
        <nav className="flex space-x-6">
          <a href="/" className="relative text-lg hover:text-gray-300 transition-colors duration-300 after:absolute after:bg-gray-300 after:h-0.5 after:left-0 after:bottom-0 after:w-0 after:transition-all after:duration-300 hover:after:w-full">
            Home
          </a>
          <a href="/portfolio" className="relative text-lg hover:text-gray-300 transition-colors duration-300 after:absolute after:bg-gray-300 after:h-0.5 after:left-0 after:bottom-0 after:w-0 after:transition-all after:duration-300 hover:after:w-full">
            Portfolio
          </a>
          <a href="/services" className="relative text-lg hover:text-gray-300 transition-colors duration-300 after:absolute after:bg-gray-300 after:h-0.5 after:left-0 after:bottom-0 after:w-0 after:transition-all after:duration-300 hover:after:w-full">
            Services
          </a>
          <a href="/projects" className="relative text-lg hover:text-gray-300 transition-colors duration-300 after:absolute after:bg-gray-300 after:h-0.5 after:left-0 after:bottom-0 after:w-0 after:transition-all after:duration-300 hover:after:w-full">
            Projects
          </a>
          <a href="/contact" className="relative text-lg hover:text-gray-300 transition-colors duration-300 after:absolute after:bg-gray-300 after:h-0.5 after:left-0 after:bottom-0 after:w-0 after:transition-all after:duration-300 hover:after:w-full">
            Contact
          </a>
        </nav>
      </div>
    </header>
  );
};

export default Header;
