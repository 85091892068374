// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Projects from './Components/Projects';
import Contact from './Components/Contact';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Portfolio from './Components/Portfolio';
import Services from './Components/Services';
import Home from './Components/Home';

const App = () => {
  const location = useLocation();

  return (
    <div className="min-h-screen bg-gray-900 text-white flex flex-col">
      {/* Conditionally render Header only if not on the Home page */}
      {location.pathname !== '/' && <Header />}
      <main className="flex-grow">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
};

const AppWithRouter = () => (
  <Router>
    <App />
  </Router>
);

export default AppWithRouter;
