// src/components/Services.js
import React from 'react';
import { motion } from 'framer-motion'; // Import Framer Motion for animations

const Services = () => {
  // Define animation variants for each service card
  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  return (
    <section id="services" className="py-12 bg-alabaster">
      <div className="container mx-auto text-center">
        <motion.h2
          className="text-4xl font-bold text-deep-slate-blue mb-8"
          initial="hidden"
          animate="visible"
          variants={fadeInUp}
        >
          Our Services
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Residential Design */}
          <motion.div
            className="bg-white p-6 rounded-lg shadow-lg"
            initial="hidden"
            animate="visible"
            variants={fadeInUp}
          >
            <h3 className="text-2xl font-bold text-deep-slate-blue mb-4">Residential Design</h3>
            <p className="text-light-slate-gray mb-4">
              We specialize in designing elegant, functional residential spaces tailored to our clients' lifestyles.
              Whether it's a modern apartment or a luxurious villa, we make homes that stand out.
            </p>
            <a href="/services/residential-design" className="text-gold font-bold hover:text-yellow-500">Learn More</a>
          </motion.div>

          {/* Commercial Design */}
          <motion.div
            className="bg-white p-6 rounded-lg shadow-lg"
            initial="hidden"
            animate="visible"
            variants={fadeInUp}
          >
            <h3 className="text-2xl font-bold text-deep-slate-blue mb-4">Commercial Design</h3>
            <p className="text-light-slate-gray mb-4">
              Our commercial designs focus on functionality, aesthetics, and brand alignment, 
              ensuring your space is both professional and inspiring for employees and customers.
            </p>
            <a href="/services/commercial-design" className="text-gold font-bold hover:text-yellow-500">Learn More</a>
          </motion.div>

          {/* Landscape Design */}
          <motion.div
            className="bg-white p-6 rounded-lg shadow-lg"
            initial="hidden"
            animate="visible"
            variants={fadeInUp}
          >
            <h3 className="text-2xl font-bold text-deep-slate-blue mb-4">Landscape Design</h3>
            <p className="text-light-slate-gray mb-4">
              Our landscape designs integrate natural elements with architectural structures, 
              creating serene outdoor spaces that elevate the beauty and value of your property.
            </p>
            <a href="/services/landscape-design" className="text-gold font-bold hover:text-yellow-500">Learn More</a>
          </motion.div>

          {/* Interior Design */}
          <motion.div
            className="bg-white p-6 rounded-lg shadow-lg"
            initial="hidden"
            animate="visible"
            variants={fadeInUp}
          >
            <h3 className="text-2xl font-bold text-deep-slate-blue mb-4">Interior Design</h3>
            <p className="text-light-slate-gray mb-4">
              Our interior design services focus on creating spaces that are not only visually stunning 
              but also comfortable and functional, reflecting your personality and taste.
            </p>
            <a href="/services/interior-design" className="text-gold font-bold hover:text-yellow-500">Learn More</a>
          </motion.div>

          {/* Sustainable Architecture */}
          <motion.div
            className="bg-white p-6 rounded-lg shadow-lg"
            initial="hidden"
            animate="visible"
            variants={fadeInUp}
          >
            <h3 className="text-2xl font-bold text-deep-slate-blue mb-4">Sustainable Architecture</h3>
            <p className="text-light-slate-gray mb-4">
              We offer eco-friendly architectural solutions that minimize environmental impact, 
              promoting sustainability while maximizing energy efficiency in every project.
            </p>
            <a href="/services/sustainable-architecture" className="text-gold font-bold hover:text-yellow-500">Learn More</a>
          </motion.div>

          {/* Urban Planning */}
          <motion.div
            className="bg-white p-6 rounded-lg shadow-lg"
            initial="hidden"
            animate="visible"
            variants={fadeInUp}
          >
            <h3 className="text-2xl font-bold text-deep-slate-blue mb-4">Urban Planning</h3>
            <p className="text-light-slate-gray mb-4">
              Our urban planning services ensure that we design livable, vibrant cities and communities 
              that balance infrastructure, green spaces, and aesthetics for modern living.
            </p>
            <a href="/services/urban-planning" className="text-gold font-bold hover:text-yellow-500">Learn More</a>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Services;
